<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md w-full space-y-4">
      <h1 class="text-3xl font-bold text-center text-gray-900">LogBase 巡检报表</h1>
      <div class="rounded-lg border bg-white shadow-sm">
        <div class="p-6 space-y-4">
          <div class="space-y-2 text-left">
            <label
              class="text-sm font-medium"
              for="file"
            >
              <a 
                class="font-bold underline text-rose-700" 
                href="#"
                @click="downloadConsoleJS"
                >#获取控制台命令</a>
            </label>
            <input
              class="flex h-10 w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              id="file"
              type="file"
              accept=".json,application/json"
              @change="onFileChange"
            />
          </div>
          <button
            id="submit_button"
            class="inline-flex items-center justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white bg-slate-900 rounded-md shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-700"
            @click="processFile"
          >
            上传数据文件
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'; // 导入封装好的API模块

export default {
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async processFile() {
      // set button
      var button_element = document.getElementById('submit_button');
      button_element.disabled = true;
      button_element.innerHTML = `
<span>
  <span aria-hidden="true" role="status" class="inline-block rounded-full animate-spin">
  🤸🏽‍♀️
  </span>
  数据文件上传中...
</span>`;
      if (!this.selectedFile) {
        alert('请上传数据文件');
        button_element.disabled = false;
        button_element.innerHTML = '上传数据文件';
        return;
      }

      const formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        const response = await api.uploadFile(formData); // 使用api模块中的uploadFile方法
        // console.log(response.data.file);
        this.$router.push({
              name: 'ReportGenerate',
              query: { info: JSON.stringify({
                data: response.data.data,
                file: response.data.file
              })}
            });
        // if (response.data.result && response.data.file) {
        //   await api.downloadFile(response.data.file); // 调用downloadFile方法下载文件
        // } else {
        //   alert('文件处理失败：' + response.data.err);
        // }
      } catch (error) {
        console.error('Error:', error);
        alert('上传失败。');
      }
      button_element.disabled = false;
      button_element.innerHTML = '上传数据文件';
    },
    async downloadConsoleJS() {
      try {
        await api.downloadFile("console.js"); // downloadFile
      } catch (error) {
        alert('下载失败:', error);
      }
    }
  },
};
</script>

<style scoped>
/* 额外的样式可以在这里添加 */
</style>

