<template>
  <div id="app">
    <FileUpload />
  </div>
</template>

<script>
import FileUpload from './FileUpload.vue';

export default {
  components: {
    FileUpload,
  },
};
</script>
