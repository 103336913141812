import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/MainPage.vue';
import ReportGenerate from '@/components/ReportGenerate.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
    meta: { title: 'index' }
  },
  {
    path: '/report',
    name: 'ReportGenerate',
    component: ReportGenerate,
    props: route => ({ info: route.query.info }),
    meta: { title: 'report' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Set the document title dynamically based on the route's meta title
router.beforeEach((to, from, next) => {
  // document.title = 'LogBase 报表 - ' + to.meta.title;
  document.title = 'LogBase 巡检报表';
  next();
});

export default router;

