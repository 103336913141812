<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md w-full space-y-4">
      <h1 class="text-3xl font-bold text-center text-gray-900">LogBase 巡检报表</h1>


        <form @submit.prevent="handleSubmit" class="bg-white dark:bg-gray-800 shadow-lg rounded px-8 pt-6 pb-8 mb-4 border border-gray-300">
          <input v-model="form.file" id="file" style="display: none;" />
          <div class="mb-4 flex items-center">
            <label for="client" class="text-sm font-medium text-gray-700 dark:text-gray-300 mr-4">客户名称</label>
            <input
              type="text"
              id="client_name"
              v-model="form.client_name"
              class="mt-1 flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            />
          </div>
          <div class="mb-4 flex space-x-4 items-center">
            <div class="flex items-center flex-1">
              <label for="name" class="text-sm font-medium text-gray-700 dark:text-gray-300 mr-4">巡检人员</label>
              <input 
                type="text" 
                id="name" 
                v-model="form.name"
                class="mt-1 flex-1 w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" 
                placeholder="请输入"
              />
            </div>
            <div class="flex items-center flex-1">
              <label for="date" class="text-sm font-medium text-gray-700 dark:text-gray-300 mr-4">日期</label>
              <input
                type="date"
                v-model="form.date"
                id="date"
                class="mt-1 block w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <button
            id="submit_button"
            class="inline-flex items-center justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white bg-slate-900 rounded-md shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-700"
            @click="processFile"
          >
            下载报表
          </button>
          </div>
        </form>


    </div>
  </div>
</template>

<script>
import api from '../api'; // 导入封装好的API模块
// import DatePicker from 'vue3-datepicker'

export default {
  props: {
    info: {
      type: [Object, String], // 允许是对象或字符串类型
      required: true
    }
  },
  // components: {
  //   DatePicker
  // },
  data() {
    if (typeof this.info === 'string') {
      this.local_info = JSON.parse(this.info);
    } else {
      this.local_info = this.info;
    }
    // 初始化表单对象
    this.form = {
        client_name: '',
        name: '',
        date: '',
        file: '',
      }
    return {
      local_info: this.local_info // 本地数据属性
    };
  },
  methods: {
    // 设置日期为默认今天
    setTodayDate() {
      // 获取今天的日期
      const today = new Date();
      // 格式化为 yyyy-mm-dd
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始
      const dd = String(today.getDate()).padStart(2, '0');
      this.form.date = `${yyyy}-${mm}-${dd}`;
    },
    // 提交表单
    async handleSubmit() {
        var isEmpty = false;
        var button_element = document.getElementById('submit_button');

        // 检查空值
        for (const key in this.form) {
          var inputElement = document.getElementById(key);
          if (!this.form[key]) {
            inputElement.classList.add('border-red-500'); // Tailwind CSS class for red border
            isEmpty = true;
          } else {
            inputElement.classList.remove('border-red-500');
          }
        }
  
        // 如果表单验证通过，继续处理提交逻辑
        if (isEmpty === false) {
          console.log('表单提交', this.form);
          button_element.disabled = true;
          button_element.innerHTML = `
<span>
  <span aria-hidden="true" role="status" class="inline-block rounded-full animate-spin">
  🤸🏽‍♀️
  </span>
  生成中...
</span>`;
          var data = {
            client_name: this.form.client_name,
            name: this.form.name,
            date: this.form.date,
            file: this.form.file,
          }
  
          try {
            const response = await api.submitReportInfo(data); 
            // console.log(response.data);
            if (response.data.result && response.data.file) {
              await api.downloadFile(response.data.file); // downloadFile
            } else {
              alert('文件处理失败：' + response.data.err);
            }
          } catch (error) {
            alert('提交失败:', error);
            // 可以根据需要在这里添加提交失败后的处理逻辑
          }
          button_element.disabled = false;
          button_element.innerHTML = '下载报表';
        }
      }
  },
  created() {
    // 初始化 client_name 的值
    this.form.client_name = this.local_info.data.client_name;
    this.form.file = this.local_info.file;
    // 初始化 date 的值
    this.setTodayDate();
  }
};
</script>